import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useField } from "formik";

const RadioField = ({
    name,
    label,
    options,
}: {
    name: string;
    label: string;
    options: {
        value: string;
        label: string;
        labelPlacement?: "end" | "start" | "bottom" | "top";
    }[];
}) => {
    const [field, meta, helper] = useField(name);
    const isValid = !meta.error;
    const isInvalid = meta.touched && !isValid;
    return (
        <>
            <FormControl>
                <FormLabel id={"radio-buttons-" + name}>{label}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby={"radio-buttons-" + name}
                    name={field.name}
                    value={field.value}
                    onChange={(event) => {
                        helper.setValue(event.target.value);
                    }}
                    defaultValue="top"
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={"value-" + option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            labelPlacement={option.labelPlacement}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </>
    );
};

export default RadioField;
