import * as React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useTranslation from "components/customHooks/translations";
const SubmitCloseButton = ({
  submitAction,
  isSubmitting,
  closeAction,
  isValid,
  validateForm,
}: {
  submitAction: any;
  isSubmitting: any;
  closeAction: any;
  isValid: boolean;
  validateForm: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const translation = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ButtonGroup variant="contained">
        <LoadingButton
          variant="contained"
          onClick={() => {
            validateForm().then((errors: any) => {
              submitAction().then(() => {
                if (Object.keys(errors).length === 0) {
                  closeAction();
                }
              });
            });
          }}
          loading={isSubmitting}
        >
          {translation.saveAndCloseButton}
        </LoadingButton>
        <Button size="small" onClick={handleClick}>
          <ArrowDropDown></ArrowDropDown>
        </Button>
      </ButtonGroup>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            closeAction();
          }}
        >
          {translation.closeButton}
        </MenuItem>
        <MenuItem
          onClick={() => {
            submitAction();
            handleClose();
          }}
        >
          {translation.saveButton}
        </MenuItem>
      </Menu>
    </>
  );
};

export default SubmitCloseButton;
