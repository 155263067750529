export const companyData = [
  {
    field: "companyName",
    label: "Company Name",
  },
  {
    field: "companyLegalName",
    label: "Company Legal Name",
  },
  {
    field: "addressLine1",
    label: "Address line 1",
  },
  {
    field: "addressLine2",
    label: "Address line 2",
  },
  {
    field: "city",
    label: "City",
  },
  {
    field: "state",
    label: "State/Province",
  },
  {
    field: "zipCode",
    label: "Postal Code",
  },
  {
    field: "country",
    label: "Country",
  },
  {
    field: "vatNumber",
    label: "Vat Number",
  },
  {
    field: "regNo",
    label: "Registry number",
  },
  {
    field: "otherId",
    label: "Other ID",
  }
]