import React from "react";
import useTranslation from "components/customHooks/translations";
import { useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
    AutoStoriesOutlined,
    KebabDiningOutlined,
    SchemaOutlined,
    StadiumOutlined,
    StorefrontOutlined,
} from "@mui/icons-material";

interface LinkTabProps {
    label?: string;
    href?: string;
    value?: string;
    icon?: any;
}

function LinkTab(props: LinkTabProps) {
    return <Tab sx={{ minHeight: "50px" }} iconPosition="start" {...props} />;
}

const SettingsMenu = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(newValue);
    };

    return (
        <Box sx={{ width: "100%", mb: 6 }}>
            <Tabs
                onChange={handleChange}
                value={
                    pathname.includes("infrastructure")
                        ? routeNames.infrastructure
                        : pathname
                }
                variant="fullWidth"
            >
                <LinkTab
                    icon={<StorefrontOutlined />}
                    label={translation.businessSettingsHeader}
                    value={routeNames.companyInfo}
                />
                <LinkTab
                    icon={<AutoStoriesOutlined />}
                    label={translation.brandBookHeader}
                    value={routeNames.brandBook}
                />
                <LinkTab
                    icon={<SchemaOutlined />}
                    label={translation.infrastructureHeader}
                    value={routeNames.infrastructure}
                />
                <LinkTab
                    icon={<KebabDiningOutlined />}
                    label={translation.customOptions}
                    value={routeNames.generalCustomOptions}
                />
            </Tabs>
        </Box>
    );
};

export default SettingsMenu;
