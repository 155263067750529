import * as React from "react";

import Divider from "@mui/material/Divider";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Stack, Box, CardMedia, Card } from "@mui/material";
import defaultImage from "assets/img/placeholder-image.png";
import { isDiscountActive } from "helpers";
import { useUserContext } from "components/contexts/UserContext";

export default function OrderPrice({
    price,
    currency,
    originalPrice,
    originalCurrency,
    rate,
    primary = true,
    isDiscount = false,
}: any) {
    return (
        <Stack>
            <Typography
                sx={{
                    pt: 1,
                }}
                color={primary ? "text.primary" : "text.secondary"}
            >
                {(isDiscount ? "-" : "") + price + " " + currency}
            </Typography>
            {rate && (
                <>
                    <Typography
                        sx={{
                            fontSize: 12,
                        }}
                        color={"text.disabled"}
                    >
                        {(isDiscount ? "-" : "") +
                            originalPrice +
                            " " +
                            originalCurrency}
                    </Typography>
                    {!primary && (
                        <Typography
                            sx={{
                                fontSize: 12,
                            }}
                            color={"text.disabled"}
                        >
                            {"1 " +
                                originalCurrency +
                                " = " +
                                rate +
                                " " +
                                currency}
                        </Typography>
                    )}
                </>
            )}
        </Stack>
    );
}
