import * as React from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import AutocompleteField from "components/Form/AutocompleteField";
import countryList from "helpers/countryList";
import { useUserContext } from "components/contexts/UserContext";
import { createNewTenant } from "services/tenants";
import { routeNames } from "routes";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import TemplateField from "components/Form/TemplateField/TemplateField";
import { Paper, Stack } from "@mui/material";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";

const steps = ["Company Info", "Application Info"];

const InfraWizard = () => {
  const translation = useTranslation();

  const InfraWizardSchema = [
    Yup.object().shape({
      companyName: Yup.string()
        .min(2, translation.signUpShortError)
        .max(50, translation.signUpLongError)
        .required(translation.required),
      companyLegalName: Yup.string()
        .min(2, translation.signUpShortError)
        .max(50, translation.signUpLongError)
        .required(translation.required),

      addressLine1: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      addressLine2: Yup.string().max(255, translation.signUpLongError),
      city: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      state: Yup.string()
        .min(2, translation.signUpShortError)
        .max(127, translation.signUpLongError)
        .required(translation.required),
      zipCode: Yup.string()
        .min(4, translation.signUpShortError)
        .max(31, translation.signUpLongError)
        .required(translation.required),
      country: Yup.string()
        .min(2, translation.signUpShortError)
        .max(127, translation.signUpLongError)
        .required(translation.required),
    }),
    Yup.object().shape({
      template: Yup.string().required(translation.required),
    }),
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const currentValidationSchema = InfraWizardSchema[activeStep];
  const navigate = useNavigate();
  const [formError, setFormError] = React.useState("");
  const { userId, refreshUser, selectTenant, updateTrigger, setUpdateTrigger } =
    useUserContext();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  async function submitForm(values: any, actions: any) {
    const responsePromise = createNewTenant({
      ...values,
      users: [`users/${userId}`],
    });

    responsePromise
      .then((res) => {
        if (res.status === 201) {
          refreshUser().then(() => {
            const tenantIds = localStorage.getItem("tenantIds");
            if (tenantIds) {
              const tenantIdsArray = tenantIds.split(",");
              setUpdateTrigger(!updateTrigger);
              selectTenant((tenantIdsArray.length - 1).toString());
              navigate(routeNames.companyInfo, {
                state: {
                  show: true,
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        setFormError(error.response.data["hydra:description"]);
        actions.setSubmitting(false);
      });

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values: any, actions: any) {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  return (
    <>
      <Typography variant="h2" align="center" mb={5}>
        {translation.infraWizardHeader}
      </Typography>

      <Formik
        initialValues={{
          companyName: "",
          companyLegalName: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
          template: "ecommerce-1",
          country: "",
          shareCapital: "",
          vatNumber: "",
          regNo: "",
          otherId: "",
        }}
        validationSchema={currentValidationSchema}
        onSubmit={(values, actions) => {
          _handleSubmit(values, actions);
        }}
      >
        {({ handleSubmit, errors, touched, isValid, isSubmitting }) => (
          <form noValidate className="" onSubmit={handleSubmit}>
            <Box sx={{ width: "100%" }}>
              <Box mb={5}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: {
                      completed?: boolean;
                    } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Paper sx={{ p: 2, width: 500 }}>
                  <Typography className="error-label">{formError}</Typography>
                  {activeStep === 0 && (
                    <Stack spacing={1}>
                      <Stack direction={"row"} spacing={2}>
                        <FormTextField
                          label={translation.companyName}
                          name="companyName"
                        />
                        <FormTextField
                          label={translation.companyLegalName}
                          name="companyLegalName"
                        />
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Box flexGrow={1}>
                          <Stack>
                            <FormTextField
                              label={translation.tenant.vatNumber}
                              name="vatNumber"
                            />
                          </Stack>
                        </Box>
                        <Box flexGrow={1}>
                          <Stack>
                            <FormTextField
                              label={translation.tenant.regNo}
                              name="regNo"
                            />
                          </Stack>
                        </Box>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Box flexGrow={1}>
                          <Stack>
                            <FormTextField
                              label={translation.tenant.shareCapital}
                              name="shareCapital"
                            />
                          </Stack>
                        </Box>
                        <Box flexGrow={1}>
                          <Stack>
                            <FormTextField
                              label={translation.tenant.otherId}
                              name="otherId"
                            />
                          </Stack>
                        </Box>
                      </Stack>
                      <FormTextField
                        label={translation.addressLine1}
                        name="addressLine1"
                      />
                      <FormTextField
                        label={translation.addressLine2}
                        name="addressLine2"
                      />

                      <Stack direction={"row"} spacing={2}>
                        <FormTextField label={translation.city} name="city" />
                        <FormTextField label={translation.state} name="state" />
                      </Stack>

                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"flex-end"}
                      >
                        <FormTextField
                          label={translation.zipCode}
                          name="zipCode"
                        />
                        <AutocompleteCountryField
                          controlId="country01"
                          label={translation.country}
                          type="text"
                          name="country"
                          options={countryList}
                        />
                      </Stack>
                    </Stack>
                  )}
                  {activeStep >= 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <TemplateField name="template" />
                    </Box>
                  )}
                  <React.Fragment>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                      }}
                    >
                      <Button
                        color="inherit"
                        variant={"outlined"}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />

                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        type="submit"
                      >
                        {activeStep === steps.length - 1
                          ? translation.infraWizardButton
                          : translation.nextButton}
                      </LoadingButton>
                    </Box>
                  </React.Fragment>
                </Paper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default InfraWizard;
