import { CircularProgress } from "@mui/material";

const LoadingIcon = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1em",
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    );
};

export default LoadingIcon;
