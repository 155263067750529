import * as React from "react";
import { useField } from "formik";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import ding from "assets/sounds/ding.mp3";

const CheckboxAudioField = ({
    label,
    name,
    disabled,
}: {
    label?: string;
    name: string;
    disabled?: boolean;
}) => {
    const [audio] = React.useState(new Audio(ding));
    const [field, _, helper] = useField(name);
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={field.value}
                        disabled={disabled}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            if (event.target.checked === true) {
                                audio.play();
                            }
                            helper.setValue(event.target.checked);
                        }}
                    />
                }
                label={label}
            />
        </FormGroup>
    );
};

export default CheckboxAudioField;
