import * as React from "react";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { backendURL } from "config";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { logout } from "services/users";
import { Paper, Stack, Typography, Button, Box } from "@mui/material";

const Verify = (props: any) => {
    const { state }: any = useLocation();
    const translation = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState("");
    const [resending, setResending] = useState(false);
    const [resendError, setResendError] = useState("");
    const [resendSuccess, setResendSuccess] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // Update the document title using the browser API
        const id = searchParams.get("id");
        const expires = searchParams.get("expires");
        const signature = searchParams.get("signature");
        const token = searchParams.get("token");
        if (id && expires && signature && token) {
            const verifyUrl =
                backendURL +
                "/admin/verify?expires=" +
                encodeURIComponent(expires) +
                "&id=" +
                encodeURIComponent(id) +
                "&signature=" +
                encodeURIComponent(signature) +
                "&token=" +
                encodeURIComponent(token);

            axios
                .get(verifyUrl)
                .then((response) => {
                    if (response.status === 200) {
                        setSuccess(true);
                    }
                })
                .catch((error) => {
                    setError("Error: " + error.response.data.error);
                });
        }

        if (state?.email) {
            setError(translation.verifyEmailError);
        }
    }, []);

    const resendVerificationEmail = () => {
        setResending(true);
        const id = localStorage.getItem("id");
        if (id) {
            const resendUrl =
                backendURL + "/admin/resend?id=" + encodeURIComponent(id);
            axios
                .get(resendUrl)
                .then((response) => {
                    if (response.status === 200) {
                        setResendSuccess(true);
                    }
                    setResending(false);
                })
                .catch((error) => {
                    setResendError("Error: " + error.response.data.error);
                    setResending(false);
                });
        }
    };

    const sendToLogIn = () => {
        logout().then(() => (window.location.href = routeNames.home));
    };

    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            sx={{
                height: "100vh",
            }}
        >
            <Paper sx={{ p: 4 }}>
                {error === "" ? (
                    success === false ? (
                        <>
                            <Typography variant="h3">
                                {translation.verifyHeader}
                            </Typography>
                            <Typography variant="h5">
                                {translation.verifyHeaderMessage}
                            </Typography>
                            <Typography sx={{ py: 2 }}>
                                {translation.verifyMessage}
                            </Typography>
                            <Stack alignItems={"center"} spacing={3}>
                                <Box>
                                    <Button
                                        onClick={resendVerificationEmail}
                                        disabled={resending}
                                        variant={"contained"}
                                    >
                                        {
                                            translation.resendVerificationEmailButton
                                        }
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        onClick={sendToLogIn}
                                        variant={"outlined"}
                                    >
                                        {translation.signOut}
                                    </Button>
                                </Box>
                            </Stack>
                            {resendSuccess && (
                                <Typography sx={{ pt: 3 }}>
                                    {translation.resendVerificationEmailSuccess}
                                </Typography>
                            )}
                            {resendError && (
                                <Typography sx={{ pt: 3, color: "error.main" }}>
                                    {resendError}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography variant="h3">
                                {translation.verifyHeader}
                            </Typography>
                            <Typography variant="h5">
                                {translation.verifyHeaderVerifiedMessage}
                            </Typography>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{ pt: 2 }}
                            >
                                <Button
                                    onClick={sendToLogIn}
                                    variant="contained"
                                >
                                    {translation.signInButton}
                                </Button>
                            </Box>
                        </>
                    )
                ) : (
                    <Typography sx={{ pt: 3, color: "error.main" }}>
                        {error}
                    </Typography>
                )}
            </Paper>
        </Stack>
    );
};

export default Verify;
