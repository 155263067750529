import React from "react";
import { useField } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const DatePickerField = ({ label, name }: { label?: string; name: string }) => {
    const [field, meta, helper] = useField(name);
    const isValid = !meta.error;
    const isInvalid = meta.touched && !isValid;
    return (
        <>
            <DatePicker
                onChange={(newValue) => {
                    helper.setValue(newValue?.toISOString());
                }}
                value={field.value ? dayjs(field.value) : null}
                label={label}
                defaultValue={
                    meta.initialValue ? dayjs(meta.initialValue) : null
                }
                slotProps={{
                    textField: {
                        error: !!meta.error,
                        helperText: meta.error,
                    },
                }}
            />
        </>
    );
};

export default DatePickerField;
