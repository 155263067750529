export const recordTypes = ["A", "CNAME", "MX", "TXT"];
export const recordTTLs = {
    1: "auto",
    60: "1 min",
    120: "2 min",
    300: "5 min",
    600: "10 min",
    900: "15 min",
    1800: "30 min",
    3600: "1 hr",
    7200: "2 hr",
    18000: "5 hr",
    43200: "12 hr",
    86400: "1 day",
};
