import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import { useUserContext } from "components/contexts/UserContext";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import ProductsForm from "./ProductsForm";

const NewProducts = () => {
  const { tenantsIsLoading, selectedTenant } = useUserContext();
  const translation = useTranslation();

  return (
    <>
      {tenantsIsLoading ? (
        <LoadingIcon />
      ) : (
        selectedTenant != null && (
          <>
            <PageHeader>{translation.newProductTitle}</PageHeader>
            <ProductsForm></ProductsForm>
          </>
        )
      )}
    </>
  );
};

export default NewProducts;
