/* eslint-disable react/display-name */
import { Card } from "@mui/material";
import React, { forwardRef, useState } from "react";

export const Photo = forwardRef(
  (
    { url, index, faded, style, isDragging, isOverlay = false, ...props },
    ref
  ) => {
    const inlineStyles = {
      position: "relative",
      opacity: faded ? "0.2" : "1",
      transformOrigin: "0 0",
      height: index === 0 ? 410 : 200,
      gridRowStart: index === 0 ? "span 2" : null,
      gridColumnStart: index === 0 ? "span 2" : null,
      backgroundImage: isDragging && !isOverlay ? "none" : `url("${url}")`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "4px",
      cursor: isDragging ? "grabbing" : "grab",
      ...style,
    };

    return (
      <Card
        ref={ref}
        style={inlineStyles}
        elevation={isOverlay ? 8 : 1}
        {...props}
      >
        {!isDragging && props.children}
      </Card>
    );
  }
);
