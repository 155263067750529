export const orderStatuses = [
    {
        slug: "new",
        label: "New",
    },
    {
        slug: "ship it",
        label: "Ship it",
    },
    {
        slug: "on the road",
        label: "On the road",
    },
    {
        slug: "feedback loop",
        label: "Feedback loop",
    },
    {
        slug: "wired",
        label: "Wired",
    },
    {
        slug: "completed",
        label: "Completed",
    },
    {
        slug: "refunded",
        label: "Refunded",
    },
    {
        slug: "canceled",
        label: "Canceled",
    },
];
