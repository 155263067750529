import * as React from "react";

import Divider from "@mui/material/Divider";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Stack, Box, CardMedia, Card, Grid, useTheme } from "@mui/material";
import defaultImage from "assets/img/placeholder-image.png";
import { isDiscountActive } from "helpers";
import { useUserContext } from "components/contexts/UserContext";
import OrderPrice from "./OrderPrice";
import discountImage from "assets/img/discount.png";

export default function OrderProductsList({ order }) {
    const { selectedTenant, tenants } = useUserContext();
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            {order.products.map((orderProduct, index) => {
                const discountAmount = orderProduct.netDiscount
                    ? tenants[selectedTenant].settings.taxIncluded
                        ? orderProduct.netDiscount
                        : orderProduct.grossDiscount
                    : null;
                if (orderProduct.productSnapshot) {
                    const price = tenants[selectedTenant].settings.taxIncluded
                        ? orderProduct.price.netPrice
                        : orderProduct.price.grossPrice;
                    const currency = orderProduct.price.priceCurrency;
                    const originalPrice = tenants[selectedTenant].settings
                        .taxIncluded
                        ? orderProduct.productSnapshot.isVariable &&
                          orderProduct.variationSnapshot
                            ? orderProduct.variationSnapshot.netPrice
                            : orderProduct.productSnapshot.netPrice
                        : orderProduct.productSnapshot.isVariable &&
                          orderProduct.variationSnapshot
                        ? orderProduct.variationSnapshot.grossPrice
                        : orderProduct.productSnapshot.grossPrice;

                    return (
                        <Box
                            key={orderProduct["@id"]}
                            sx={{ textAlign: "right" }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Stack
                                        direction={"row"}
                                        spacing={2}
                                        sx={{ paddingBottom: 2 }}
                                    >
                                        <Card
                                            sx={{
                                                maxWidth: 75,
                                                minWidth: 75,
                                                height: 75,
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="75"
                                                width="75"
                                                image={
                                                    orderProduct.productSnapshot
                                                        .images.length > 0
                                                        ? orderProduct
                                                              .productSnapshot
                                                              .images[0]
                                                              .contentUrl
                                                        : defaultImage
                                                }
                                            ></CardMedia>
                                        </Card>
                                        <Stack justifyContent={"space-between"}>
                                            <Typography>
                                                {
                                                    orderProduct.productSnapshot
                                                        .name
                                                }
                                            </Typography>
                                            {orderProduct.product.isVariable &&
                                                orderProduct.variation && (
                                                    <Stack direction="row">
                                                        <Box
                                                            sx={{
                                                                backgroundColor:
                                                                    theme
                                                                        .palette
                                                                        .primary
                                                                        .main,
                                                                py: 1,
                                                                px: 2,
                                                                borderRadius:
                                                                    "0 8px",
                                                            }}
                                                        >
                                                            <Typography color="white">
                                                                {
                                                                    orderProduct
                                                                        .variation
                                                                        .name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                )}

                                            {orderProduct.product.hasStock && (
                                                <Typography variant={"caption"}>
                                                    {
                                                        orderProduct.product
                                                            .stockCount
                                                    }{" "}
                                                    in stock
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box sx={{ textAlign: "right" }}>
                                            <Typography
                                                sx={{ p: 1 }}
                                                color={"text.secondary"}
                                            >
                                                {orderProduct.quantity + " x "}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2}>
                                    <OrderPrice
                                        price={price}
                                        currency={currency}
                                        originalPrice={originalPrice}
                                        originalCurrency={
                                            orderProduct.product.priceCurrency
                                        }
                                        rate={orderProduct.rate}
                                        primary={false}
                                    ></OrderPrice>
                                </Grid>
                                <Grid item xs={2}>
                                    <OrderPrice
                                        price={price * orderProduct.quantity}
                                        currency={currency}
                                        originalPrice={
                                            originalPrice *
                                            orderProduct.quantity
                                        }
                                        originalCurrency={
                                            orderProduct.product.priceCurrency
                                        }
                                        rate={orderProduct.rate}
                                    ></OrderPrice>
                                </Grid>
                            </Grid>
                            {orderProduct.hasDiscount && discountAmount && (
                                <Grid
                                    sx={{ mt: 1, mb: 2 }}
                                    container
                                    spacing={2}
                                >
                                    <Grid item xs={6}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Card
                                                sx={{
                                                    minWidth: 75,
                                                    maxWidth: 75,
                                                    height: 75,
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    height="75"
                                                    width="75"
                                                    image={discountImage}
                                                ></CardMedia>
                                            </Card>
                                            <Typography>
                                                {"Product Discount (" +
                                                    (tenants[selectedTenant]
                                                        .settings.taxIncluded
                                                        ? Math.round(
                                                              (discountAmount /
                                                                  orderProduct
                                                                      .price
                                                                      .netPrice) *
                                                                  10000
                                                          ) / 100
                                                        : Math.round(
                                                              (discountAmount /
                                                                  orderProduct
                                                                      .price
                                                                      .grossPrice) *
                                                                  10000
                                                          ) / 100) +
                                                    "%)"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                alignItems={"center"}
                                            >
                                                <Typography
                                                    sx={{ p: 1 }}
                                                    color={"text.secondary"}
                                                >
                                                    {orderProduct.quantity +
                                                        " x "}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <OrderPrice
                                            price={discountAmount}
                                            currency={currency}
                                            originalPrice={
                                                Math.round(
                                                    (discountAmount /
                                                        orderProduct.rate) *
                                                        100
                                                ) / 100
                                            }
                                            originalCurrency={
                                                orderProduct.product
                                                    .priceCurrency
                                            }
                                            rate={orderProduct.rate}
                                            isDiscount={true}
                                            primary={false}
                                        ></OrderPrice>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <OrderPrice
                                            price={
                                                discountAmount *
                                                orderProduct.quantity
                                            }
                                            currency={currency}
                                            originalPrice={
                                                Math.round(
                                                    ((discountAmount *
                                                        orderProduct.quantity) /
                                                        orderProduct.rate) *
                                                        100
                                                ) / 100
                                            }
                                            originalCurrency={
                                                orderProduct.product
                                                    .priceCurrency
                                            }
                                            rate={orderProduct.rate}
                                            isDiscount
                                        ></OrderPrice>
                                    </Grid>
                                </Grid>
                            )}

                            <Divider></Divider>
                        </Box>
                    );
                } else {
                    return (
                        <Box key={orderProduct["@id"]}>
                            {orderProduct.hasDiscount && discountAmount && (
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ paddingBottom: 2 }}
                                >
                                    <Stack direction={"row"} spacing={2}>
                                        <Card
                                            sx={{
                                                minWidth: 75,
                                                maxWidth: 75,
                                                height: 75,
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="75"
                                                width="75"
                                                image={discountImage}
                                            ></CardMedia>
                                        </Card>
                                        <Typography>
                                            {"Order Discount (" +
                                                (tenants[selectedTenant]
                                                    .settings.taxIncluded
                                                    ? Math.round(
                                                          (discountAmount /
                                                              (order.totalPrice
                                                                  .netPrice -
                                                                  order
                                                                      .totalDiscount
                                                                      .netPrice +
                                                                  discountAmount)) *
                                                              10000
                                                      ) / 100
                                                    : Math.round(
                                                          (discountAmount /
                                                              (order.totalPrice
                                                                  .grossPrice -
                                                                  order
                                                                      .totalDiscount
                                                                      .grossPrice +
                                                                  discountAmount)) *
                                                              10000
                                                      ) / 100) +
                                                "%)"}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={2}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems={"center"}
                                        >
                                            <Typography>
                                                {"-" +
                                                    discountAmount +
                                                    tenants[selectedTenant]
                                                        .settings
                                                        .defaultCurrency}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}
                            <Divider></Divider>
                        </Box>
                    );
                }
            })}
        </Stack>
    );
}
