import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { createNewUser } from "services/users";
import { logout } from "services/users";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const SignUp = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [formError, setFormError] = React.useState("");
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    lastName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    email: Yup.string()
      .email(translation.signUpEmailError)
      .required(translation.required),
    password: Yup.string()
      .min(6, translation.signUpShortError)
      .required(translation.required),
  });

  React.useEffect(() => {
    logout();
  }, []);

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      sx={{
        height: "100vh",
      }}
    >
      <Paper sx={{ p: 4, maxWidth: 350 }}>
        <Typography variant="h5">
          {translation.signUpHeader1}
          <br />
          {translation.signUpHeader2}
        </Typography>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            // same shape as initial values
            const responsePromise = createNewUser(values);

            responsePromise
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem("id", res.data.userId);
                  navigate(routeNames.verify);
                  setSubmitting(false);
                } else if (res.status === 207) {
                  navigate(routeNames.verify, {
                    state: {
                      email: "false",
                    },
                  });
                  setSubmitting(false);
                } else {
                  setFormError(res.response.data.error);
                  setSubmitting(false);
                }
              })
              .catch((error) => console.log(error));
          }}
        >
          {({ handleSubmit, errors, touched, isValid, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <p className="error-label">{formError}</p>

              <Stack spacing={1}>
                <FormTextField
                  label={translation.signUpFirstNameLabel}
                  name="firstName"
                />
                <FormTextField
                  label={translation.signUpLastNameLabel}
                  name="lastName"
                />
                <FormTextField
                  label={translation.signUpEmailLabel}
                  name="email"
                />
                <FormTextField
                  label={translation.signUpPasswordLabel}
                  name="password"
                  type="password"
                />
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                  >
                    {translation.signUpButtonLabel}
                  </LoadingButton>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>

        <Typography variant="caption">
          {translation.signUpAlready}
          <br />
          <Link to={routeNames.signIn}>{translation.signUpAlreadyLink}</Link>
        </Typography>

        <Typography variant="h5" sx={{ pt: 2 }}>
          {translation.signUpFooter}
        </Typography>
      </Paper>
    </Stack>
  );
};

export default SignUp;
