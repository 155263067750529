import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import CouponsForm from "./CouponsForm";
import { getCoupon } from "services/tenants";
import { ICoupon } from "interfaces";

const UpdateCoupon = () => {
  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const translation = useTranslation();
  const { couponId } = useParams();

  useEffect(() => {
    if (couponId !== undefined) {
      getCoupon(couponId)
        .then((product) => {
          if (product.data) {
            setCoupon(product.data);
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [couponId]);

  return (
    <>
      {isLoading || coupon == null ? (
        <LoadingIcon />
      ) : (
        <>
          <PageHeader>{translation.editCouponTitle}</PageHeader>
          <CouponsForm couponToEdit={coupon}></CouponsForm>
        </>
      )}
    </>
  );
};

export default UpdateCoupon;
