import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ICourier, IDropTag } from "interfaces";
import { errorMessage } from "helpers";
import dayjs from "dayjs";
import DatePickerField from "components/Form/DatePickerField";
import CheckboxField from "components/Form/CheckboxField/CheckboxField";
import CheckboxAudioField from "components/Form/CheckboxAudioField/CheckboxAudioField";
import { LoadingButton } from "@mui/lab";
import AutocompleteTagsField from "components/Form/AutocompleteTagsField";
import {
    createCourier,
    deleteCourier,
    getDropTagsByTenant,
    getPickUpPointsByCourier,
    getServicesByCourier,
    updateCourier,
} from "services/tenants";
import { availableCouriers } from "helpers/couriers";

const CourierForm = ({
    showNewCourier,
    setShowNewCourier,
    courierToEdit,
    setCourierToEdit,
    updateTrigger,
    setUpdateTrigger,
    setNewCourier,
}: {
    showNewCourier: boolean;
    setShowNewCourier: React.Dispatch<React.SetStateAction<boolean>>;
    courierToEdit?: ICourier | null;
    setCourierToEdit?: React.Dispatch<React.SetStateAction<ICourier | null>>;
    updateTrigger: boolean;
    setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setNewCourier?: any;
}) => {
    const { userId, selectedTenant, tenants } = useUserContext();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
    const [openDelete, setOpenDelete] = useState(false);
    const [services, setServices] = useState<any>([]);
    const [pickupPoints, setPickupPoints] = useState<any>([]);

    const translation = useTranslation();

    const courierSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, translation.signUpShortError)
            .max(50, translation.signUpLongError)
            .required(translation.required),
    });

    const handleDeleteCourier = () => {
        if (courierToEdit) {
            setDeleteLoading(true);
            deleteCourier(courierToEdit)
                .then(() => {
                    setSuccessMessage(translation.deletedMessage);
                    setOpenDelete(false);
                    setShowNewCourier(false);
                    setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => setErrorMessage(errorMessage(e)))
                .finally(() => {
                    setDeleteLoading(false);
                });
        }
    };

    useEffect(() => {
        if (tenants !== null) {
            console.log("sd");
        }
    }, [selectedTenant, tenants, updateTrigger]);

    useEffect(() => {
        if (courierToEdit) {
            getServicesByCourier(courierToEdit).then((res) => {
                const newServices = [];
                for (const key in res.data) {
                    newServices.push({ id: key, label: res.data[key] });
                }
                setServices(newServices);
            });
            if (courierToEdit.courier === "Cargus") {
                getPickUpPointsByCourier(courierToEdit).then((res) =>
                    setPickupPoints(res.data)
                );
            }
        }
    }, [courierToEdit]);

    return (
        <>
            <Drawer
                anchor="right"
                open={showNewCourier}
                onClose={() => {
                    setShowNewCourier(false);
                    if (setCourierToEdit) {
                        setCourierToEdit(null);
                    }
                }}
                sx={{
                    "& .MuiDrawer-paper": { width: formDrawerWidth },
                }}
            >
                <Stack
                    justifyContent={"space-between"}
                    sx={{ p: 4, height: "100%" }}
                >
                    <Box>
                        <Formik
                            initialValues={{
                                name: courierToEdit ? courierToEdit.name : "",
                                courier: courierToEdit
                                    ? courierToEdit.courier
                                    : "",
                                credentials: {
                                    UserName: courierToEdit
                                        ? courierToEdit.credentials.UserName
                                            ? courierToEdit.credentials.UserName
                                            : ""
                                        : "",
                                    Password: courierToEdit
                                        ? courierToEdit.credentials.Password
                                            ? courierToEdit.credentials.Password
                                            : ""
                                        : "",
                                    ClientId: courierToEdit
                                        ? courierToEdit.credentials.ClientId
                                            ? courierToEdit.credentials.ClientId
                                            : ""
                                        : "",
                                },
                                settings: {
                                    service: courierToEdit
                                        ? courierToEdit.settings.service
                                            ? courierToEdit.settings.service
                                            : ""
                                        : "",
                                    pickupPoint: courierToEdit
                                        ? courierToEdit.settings.pickupPoint
                                            ? courierToEdit.settings.pickupPoint
                                            : ""
                                        : "",
                                },
                            }}
                            validationSchema={courierSchema}
                            enableReinitialize
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);

                                if (courierToEdit) {
                                    updateCourier({
                                        ...courierToEdit,
                                        name: values.name,
                                        credentials: {
                                            UserName:
                                                values.credentials.UserName,
                                            Password:
                                                values.credentials.Password,
                                            ClientId:
                                                values.credentials.ClientId,
                                        },
                                        settings: {
                                            service: values.settings.service,
                                            pickupPoint:
                                                values.settings.pickupPoint,
                                        },
                                    })
                                        .then(() => {
                                            setSubmitting(false);
                                            setShowNewCourier(false);
                                            if (setCourierToEdit) {
                                                setCourierToEdit(null);
                                            }
                                            setSuccessMessage(
                                                translation.savedMessage
                                            );
                                            setUpdateTrigger(!updateTrigger);
                                        })
                                        .catch((e) => {
                                            setErrorMessage(errorMessage(e));
                                            setSubmitting(false);
                                        });
                                } else {
                                    createCourier({
                                        name: values.name,
                                        courier: values.courier,
                                        credentials: {
                                            UserName:
                                                values.credentials.UserName,
                                            Password:
                                                values.credentials.Password,
                                            ClientId:
                                                values.credentials.ClientId,
                                        },
                                        tenant:
                                            "/tenants/" +
                                            tenants[selectedTenant].id,
                                    })
                                        .then((res) => {
                                            setSubmitting(false);
                                            if (setCourierToEdit) {
                                                setCourierToEdit(res.data);
                                            }
                                            setSuccessMessage(
                                                translation.savedMessage
                                            );
                                            setUpdateTrigger(!updateTrigger);
                                            if (setNewCourier) {
                                                setNewCourier(res.data["@id"]);
                                            }
                                        })
                                        .catch((e) => {
                                            setErrorMessage(errorMessage(e));
                                            setSubmitting(false);
                                        });
                                }
                            }}
                        >
                            {({ handleSubmit, isSubmitting, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <Typography variant="h5">
                                            {
                                                translation.shipping
                                                    .courierFormTitle
                                            }
                                        </Typography>
                                        <Stack spacing={4}>
                                            <FormTextField
                                                label={
                                                    translation.shipping
                                                        .nameLabel
                                                }
                                                name="name"
                                            />
                                            <SelectField
                                                label={
                                                    translation.shipping
                                                        .courierLabel
                                                }
                                                disabled={
                                                    courierToEdit !== null
                                                }
                                                name="courier"
                                            >
                                                <MenuItem value="">
                                                    None
                                                </MenuItem>
                                                {availableCouriers.map(
                                                    (availableCourier) => (
                                                        <MenuItem
                                                            key={
                                                                availableCourier.id
                                                            }
                                                            value={
                                                                availableCourier.id
                                                            }
                                                        >
                                                            {
                                                                availableCourier.label
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </SelectField>
                                            {values.courier != "" && (
                                                <>
                                                    {values.courier ===
                                                        "FanCourier" && (
                                                        <FormTextField
                                                            label={
                                                                translation
                                                                    .shipping
                                                                    .clientIdLabel
                                                            }
                                                            name="credentials.ClientId"
                                                        />
                                                    )}
                                                    <FormTextField
                                                        label={
                                                            translation.shipping
                                                                .usernameLabel
                                                        }
                                                        name="credentials.UserName"
                                                    />
                                                    <FormTextField
                                                        type="password"
                                                        label={
                                                            translation.shipping
                                                                .passwordLabel
                                                        }
                                                        name="credentials.Password"
                                                    />
                                                </>
                                            )}
                                            {courierToEdit !== null && (
                                                <SelectField
                                                    label={
                                                        translation.shipping
                                                            .serviceLabel
                                                    }
                                                    name="settings.service"
                                                >
                                                    <MenuItem value="">
                                                        None
                                                    </MenuItem>
                                                    {services.map(
                                                        (service: any) => (
                                                            <MenuItem
                                                                key={service.id}
                                                                value={
                                                                    service.id
                                                                }
                                                            >
                                                                {service.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </SelectField>
                                            )}
                                            {courierToEdit?.courier ===
                                                "Cargus" && (
                                                <SelectField
                                                    label={
                                                        translation.shipping
                                                            .pickupPointLabel
                                                    }
                                                    name="settings.pickupPoint"
                                                >
                                                    <MenuItem value="">
                                                        None
                                                    </MenuItem>
                                                    {pickupPoints.map(
                                                        (pickupPoint: any) => (
                                                            <MenuItem
                                                                key={
                                                                    pickupPoint.LocationId
                                                                }
                                                                value={
                                                                    pickupPoint.LocationId
                                                                }
                                                            >
                                                                {
                                                                    pickupPoint.Name
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </SelectField>
                                            )}
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                                justifyContent={"space-between"}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    onClick={() => {
                                                        setShowNewCourier(
                                                            false
                                                        );
                                                        if (setCourierToEdit) {
                                                            setCourierToEdit(
                                                                null
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {translation.closeButton}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {courierToEdit === null
                                                        ? translation.nextButton
                                                        : translation.saveButton}
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Box>
                    {courierToEdit && (
                        <Box
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setOpenDelete(true)}
                            >
                                {translation.deleteButton}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Drawer>
            <Dialog
                open={openDelete}
                onClose={() => {
                    setOpenDelete(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {translation.shipping.courierDeleteConfirmMessage}
                </DialogTitle>
                <DialogContent>
                    <Typography>{courierToEdit?.name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setOpenDelete(false);
                        }}
                    >
                        {translation.closeButton}
                    </Button>

                    <LoadingButton
                        onClick={() => handleDeleteCourier()}
                        variant="contained"
                        loading={deleteLoading}
                    >
                        {translation.deleteButton}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CourierForm;
