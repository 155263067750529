import React from "react";
import useTranslation from "components/customHooks/translations";
import { useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
    ImportExportOutlined,
    InterestsOutlined,
    SellOutlined,
} from "@mui/icons-material";

interface LinkTabProps {
    label?: string;
    href?: string;
    value?: string;
    icon?: any;
}

function LinkTab(props: LinkTabProps) {
    return <Tab sx={{ minHeight: "50px" }} iconPosition="start" {...props} />;
}

const ProductsMenu = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(newValue);
    };

    return (
        <Box sx={{ width: "100%", mb: 2 }}>
            <Tabs variant="fullWidth" onChange={handleChange} value={pathname}>
                <LinkTab
                    icon={<SellOutlined />}
                    label={translation.productsTitle}
                    value={routeNames.products}
                />
                <LinkTab
                    icon={<InterestsOutlined />}
                    label={translation.categoriesTitle}
                    value={routeNames.categories}
                />
                <LinkTab
                    icon={<ImportExportOutlined />}
                    label={translation.productsExportImportTitle}
                    value={routeNames.productsExportImport}
                />
            </Tabs>
        </Box>
    );
};

export default ProductsMenu;
