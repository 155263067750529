import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { IDnsZone } from "interfaces";
import DnsZone from "components/DnsZone";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { getDnsZone } from "services/dns";
import { Paper } from "@mui/material";

const DnsZonePage = () => {
    const { selectedTenant, tenants } = useUserContext();
    const [dnsZone, setDnsZone] = useState<IDnsZone | null>(null);
    const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const translation = useTranslation();
    const { dnsZoneId } = useParams();

    useEffect(() => {
        if (dnsZoneId !== undefined) {
            getDnsZone(dnsZoneId)
                .then((dns) => {
                    if (dns.data) {
                        console.log(dns.data);
                        setDnsZone(dns.data);
                        setIsLoading(false);
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [dnsZoneId, updateTrigger]);

    return (
        <>
            {isLoading || dnsZone == null || tenants == null ? (
                <LoadingIcon />
            ) : (
                <Paper sx={{ p: 2 }}>
                    <DnsZone
                        tenant={tenants[selectedTenant]}
                        zone={dnsZone}
                        updateTrigger={updateTrigger}
                        setUpdateTrigger={setUpdateTrigger}
                    ></DnsZone>
                </Paper>
            )}
        </>
    );
};

export default DnsZonePage;
