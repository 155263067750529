import { useField } from "formik";
import {
  MuiColorInput,
  MuiColorInputColors,
  MuiColorInputFormat,
} from "mui-color-input";

const ColorInputField = ({ name }: { name: string }) => {
  const format: MuiColorInputFormat = "hex";
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <MuiColorInput
      id={field.name}
      onChange={(newValue: string, colors: MuiColorInputColors) => {
        helper.setValue(newValue);
      }}
      value={field.value}
      fallbackValue="#ffffff"
      isAlphaHidden
      format={format}
      error={isInvalid}
      helperText={isInvalid ? meta.error : ""}
    />
  );
};

export default ColorInputField;
