import useTranslation from "components/customHooks/translations";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const NewCompanyCongratulations = ({ show, setShow }: any) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  return (
    <Dialog
      open={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <DialogTitle variant="h4">
        {translation.newCompanyCongrulations}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{
            __html: translation.newCompanyCongrulationsMessage,
          }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setShow(false);
          }}
        >
          {translation.okButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCompanyCongratulations;
