import * as React from "react";
import { useField } from "formik";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const SwitchField = ({
    label,
    name,
    disabled,
}: {
    label?: string;
    name: string;
    disabled?: boolean;
}) => {
    const [field, _, helper] = useField(name);
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={field.value}
                        disabled={disabled}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            helper.setValue(event.target.checked);
                        }}
                    />
                }
                label={label}
            />
        </FormGroup>
    );
};

export default SwitchField;
