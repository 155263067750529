import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { routeNames } from "routes";
import logo from "assets/img/logo.svg";
import userImage from "assets/img/placeholder.png";
import { logout } from "services/users";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { getUserById } from "services/tenants";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import {
    ArrowDropDown,
    Person,
    SettingsInputCompositeOutlined,
    ShoppingBagOutlined,
} from "@mui/icons-material";
import { Divider, Icon, Stack } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { drawerWidth } from "../SideNav";
import SelectTenant from "components/SelectTenant";

const Navbar = () => {
    const { user, isLoading, userId, updateTrigger } = useUserContext();
    const [userData, setUserData] = useState<any>(null);
    const translation = useTranslation();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        logout().then(() => (window.location.href = routeNames.home));
    };

    useEffect(() => {
        if (!isLoading) {
            getUserById(userId)
                .then((user) => {
                    if (user.data) {
                        setUserData(user.data);
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading, updateTrigger, userId]);

    return (
        <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${drawerWidth}px)`,
                ml: `${drawerWidth}px`,
            }}
            elevation={1}
        >
            <Toolbar disableGutters sx={{ marginX: 4 }}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: "none", md: "flex" },
                    }}
                ></Box>
                <Box sx={{ flexGrow: 0 }}>
                    <SelectTenant />
                </Box>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ marginX: 3 }}
                />
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                        <Stack direction="row" alignItems={"center"}>
                            <IconButton
                                onClick={handleOpenUserMenu}
                                disableRipple
                                sx={{ p: 0 }}
                            >
                                <Avatar
                                    variant="rounded"
                                    src={
                                        userData
                                            ? userData.photo
                                                ? userData.photo.contentUrl
                                                : userImage
                                            : userImage
                                    }
                                />
                                <ArrowDropDown color="primary" />
                            </IconButton>
                        </Stack>
                    </Tooltip>
                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem disabled>
                            <Typography variant="body1" gutterBottom>
                                {userData
                                    ? userData.firstName +
                                      " " +
                                      userData.lastName
                                    : translation.loading}
                            </Typography>
                        </MenuItem>
                        <MenuItem disabled>
                            <Typography variant="body2" gutterBottom>
                                {userData
                                    ? userData.email
                                    : translation.loading}
                            </Typography>
                        </MenuItem>
                        <Divider></Divider>
                        <MenuItem
                            component={Link}
                            to={routeNames.myProfile}
                            onClick={handleCloseUserMenu}
                        >
                            <ListItemIcon>
                                <Person fontSize="small" />
                            </ListItemIcon>
                            {translation.myProfile}
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to={routeNames.companyInfo}
                            onClick={handleCloseUserMenu}
                        >
                            <ListItemIcon>
                                <SettingsInputCompositeOutlined fontSize="small" />
                            </ListItemIcon>
                            {translation.companyInfoTitle}
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to={routeNames.policies}
                            onClick={handleCloseUserMenu}
                        >
                            <ListItemIcon>
                                <ShoppingBagOutlined fontSize="small" />
                            </ListItemIcon>
                            {translation.ecommerceMenu.ecommerceSettings}
                        </MenuItem>
                        <Divider></Divider>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {translation.signOut}
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
