import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import { Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import CourierIntegrations from "./CourierIntegrations";
import ShippingMethods from "./ShippingMethods";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Shipping = () => {
    const [value, setValue] = React.useState(0);
    const t = useTranslation();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label={t.shipping.methodsTab} {...a11yProps(0)} />
                    <Tab label={t.shipping.integrationsTab} {...a11yProps(1)} />
                    {/* <Tab label={t.shipping.zonesTab} {...a11yProps(2)} /> */}
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                    <ShippingMethods />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <CourierIntegrations />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    Item Three
                </CustomTabPanel>
            </Paper>
        </>
    );
};

export default Shipping;
